import { SystemProps, Flex, Grid, Box } from '@storyofams/react-ui'
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit'
import { Text, Button, Heading, FixedRatio } from '~components'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { SectionWrapper } from '~components/common/SectionWrapper';

type HowToItemType = {
  _uid: string;
  image?: {
    filename?: string,
    alt?: string,
  },
  use_fixed_image_height: boolean,
  image_height?: number,
  title?: string,
  description?: string,
}

type HowToSectionProps = {
  content: {
    title?: string;
    items?: Array<HowToItemType>;
    button_text?: string;
    button_link?: {
      cached_url?: string,
    };
    background_color?: {
      color?: string,
    };
    font_color?: {
      color?: string,
    };
    limit_items_on_mobile: boolean;
    items_limit_on_mobile?: number,
  };
  first?: boolean;
} & SystemProps;

const BlockItem = ({ item, fontColor, indexN, limitItemsOnMobile, itemsLimitOnMobile }) => {
  let styleProps = {} as any

  if (fontColor) {
    styleProps.color = fontColor
  }

  return (
    <Box
      display={[limitItemsOnMobile && itemsLimitOnMobile <= indexN ? 'none' : 'block', 'block']}
    >
      <Flex flexDirection="column" >
        {!!item?.image && (
          <Flex
            px={6}
            height={item?.use_fixed_image_height ? `${item?.image_height}px` : 'auto'}
            justifyContent={'center'}
          >
            <FixedRatio
              ratio={[[1, 1]]}
              width={item?.use_fixed_image_height ? `${item?.image_height}px` : '100%'}
            >
              <ToolkitImage
                src={item.image?.filename}
                alt={item.image?.alt}
                fluid={400}
                width={item?.use_fixed_image_height ? `${item?.image_height}px` : '100%'}
                height={'100%'}
                fit="contain"
              />
            </FixedRatio>
          </Flex>
        )}
        {!!item?.title && (
          <Flex px={1} mt={[3]} justifyContent={'center'}>
            <Heading
              as="h4"
              width="100%"
              variant="sh4"
              fontSize={['20px']}
              textAlign={'center'}
              maxWidth={'300px'}
              {...styleProps}
            >{item?.title}</Heading>
          </Flex>
        )}
        {!!item?.description && (
          <Flex px={1} mt={[1]} justifyContent={'center'}>
            <Text
              width="100%"
              whiteSpace="pre-line"
              textAlign={'center'}
              fontSize={'18px'}
              lineHeight={'130%'}
              maxWidth={'300px'}
              {...styleProps}
            >{item?.description}</Text>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

export const HowToSection = ({ content }: HowToSectionProps) => {
  return (
    <SectionWrapper backgroundColor={
      content?.background_color
        ? content?.background_color.color
        : 'auto'
    }>
      <Flex
        marginTop='0 !important'
        alignItems='center'
        width="100%"
        paddingBottom={[6, 8]}
        paddingTop={[6, 8]}
        backgroundColor={
          content?.background_color
            ? content?.background_color.color
            : 'auto'
        }
        flexDirection="column"
        position={'relative'}
      >
        {!!content?.title && (
          <Heading
            px={[2, '5%', '15%']}
            as="h3"
            variant="sh1"
            width={'100%'}
            textAlign={'center'}
            whiteSpace={['break-spaces', 'break-spaces', 'pre']}
            color={content?.font_color?.color ? content?.font_color?.color : 'inherit'}
          >{content?.title}</Heading>
        )}
        {!!content?.items
          ? (
            <Flex
              mt={3}
              px={[2, '5%', '15%']}
              width="100%"
            >
              <Box width="100%">
                <Grid
                  columnGap={[2, 8]}
                  rowGap={[4, 8]}
                  rowSize={[1, 3]}
                  width="100%"
                >
                  {content?.items.map((el, index) => <BlockItem key={el._uid} indexN={index} limitItemsOnMobile={content?.limit_items_on_mobile} itemsLimitOnMobile={content?.items_limit_on_mobile} item={el} fontColor={content?.font_color?.color}/>)}
                </Grid>
              </Box>
            </Flex>
          )
          : ''
        }
        {!!content?.button_text && (
          <Box display={['none', 'block']}>
            <Flex
              mt={[1, 2]}
              px={[2, '5%', '15%']}
              width="100%"
              justifyContent={'center'}
            >
              <Button
                width={['100%', 'auto']}
                variant="button_type_1"
                mt={'34px'}
                mb={'16px'}
                to={content?.button_link?.cached_url}
              >
                {content?.button_text}
              </Button>
            </Flex>
          </Box>
        )}
      </Flex>
    </SectionWrapper>
  );
};
